
import { defineComponent } from '@nuxtjs/composition-api';
import { EsNavBarLoggedIn, EsFooter, getEsFooterContent } from '@energysage/es-nav';
import { analyticsMixin } from '@/mixins/analytics';
import EsLogo2024 from '~/components/images/EsLogo2024.vue';

export default defineComponent({
    name: 'DefaultLayout',
    components: {
        EsNavBarLoggedIn,
        EsFooter,
        EsLogo2024,
    },
    mixins: [analyticsMixin],
    computed: {
        footerContent() {
            const footerContent = getEsFooterContent();

            // update home link to root-relative url
            footerContent.home.link = '/';

            return footerContent;
        },
    },
});
